<template>
  <div v-if="showScreen">
    <div class="evaluation">
      <br />
      <span class="heading" style="color: #642c90"
        >Subjective Evaluation For
        <span style="color: black">{{ subjectiveSolution[initial].Title }}</span></span
      >
      <hr style="color: #e0e0e0 !important" />
      <div style="display: flex; justify-content: space-between">
        <div
          class="card-panel"
          style="display: flex; flex-direction: column; width: 18%; padding: 10px !important"
        >
          <span class="heading" style="margin-top: -5px; color: #642c90">Attempted By</span>
          <div class="attempted-by">
            <div
              v-for="(data, i) in subjectiveSolution[index].userSolutionDetails"
              :key="i"
              :value="data"
            >
              <span
                class="student-name"
                style="cursor: pointer"
                :class="{ active1: studName === data.Name }"
                @click="studentData(data)"
                ><ProfileAvatar
                  :username="data.Name.charAt(0)"
                  class="leader-avatar"
                  bgColor="#C576F9 !important"
                ></ProfileAvatar
                ><span>{{ data.Name }}</span
                >&nbsp;<i
                  v-if="data.IsEvaluated == true"
                  class="material-icons icon"
                  style="color: #70ff11; height: 20px"
                  >check_circle</i
                ></span
              >
              <hr style="opacity: 0.5; border: 1px solid #e0e4f0" />
            </div>
          </div>
        </div>
        <div class="card-panel" style="display: flex; flex-direction: column; width: 80%">
          <div style="display: flex; justify-content: space-between; margin-bottom: 10px">
            <span class="heading1" style="color: black">Question No: {{ this.index + 1 }}</span>
            <div style="display: flex; justify-content: space-between; width: 250px">
              <button class="batch-button1" :disabled="this.index === 0" @click="prevQuestion()">
                Previous
              </button>
              <button
                class="batch-button1"
                :disabled="this.index === totalLength - 1"
                @click="nextQuestion()"
              >
                Next
              </button>
              <button class="batch-button2" v-if="this.index === totalLength - 1" @click="home()">
                <i class="material-icons">home</i>
              </button>
            </div>
          </div>
          <img style="height: 125px" :src="subjectiveSolution[index].QuestionDiagramURL" />
        </div>
      </div>
      <div v-if="showSolution" style="display: flex; justify-content: space-between">
        <div style="display: block; width: 60% !important">
          <span class="heading"
            >Solution By
            <span style="color: #642c90; font-size: 30px">{{
              this.studentSolution.Name
            }}</span></span
          >
          <Flicking
            align="prev"
            :plugins="plugins"
            class="flex card-radius"
            style="margin-top: 10px"
          >
            <div v-for="(data, i) in this.studentSolution.solutionUrlList" :key="i">
              <img :src="data" style="width: 100%" />
            </div>
          </Flicking>
        </div>
        <div style="width: 37%">
          <span class="heading">Review Section</span>
          <div class="card-panel">
            <div style="display: flex; flex-direction: column">
              <div>
                <span class="heading" style="display: flex"
                  >Marks:&nbsp;<input
                    style="width: 20px; height: 20px; border: 1px solid lightgrey"
                    type="text"
                    v-model="marks"
                    @keypress="validateNumber"
                  />&nbsp; / &nbsp;{{ this.studentSolution.maximumMarks }}</span
                >
                <span v-if="marksValidation" style="color: red; font-size: 12px"
                  >Marks cannot be greater then maximum marks</span
                >
                <span v-if="marksValidation1" style="color: red; font-size: 12px; margin-top: -15px"
                  >Marks cannot be negative</span
                >
                <span v-if="marksValidation2" style="color: red; font-size: 12px; margin-top: -15px"
                  >Please Enter Marks</span
                ><br />
                <span class="heading">Add Remarks</span>
                <div style="display: flex; flex-wrap: wrap; max-width: 1150px; margin-top: 10px">
                  <div v-for="points in getEvaluationPoints" :key="points.Id" style="">
                    <p>
                      <label
                        ><input
                          type="checkbox"
                          class="filled-in"
                          :class="{ checked: check === true }"
                          name="evalpoints"
                          :key="points.Id"
                          :value="points.Id"
                          v-model="addremarks"
                        />
                        <span style="color: black; font-weight: 500; width: 180px">{{
                          points.EvaluationPoint
                        }}</span>
                      </label>
                    </p>
                  </div>
                </div>
              </div>
              <div style="display: flex; flex-direction: column">
                <span class="heading">Teacher FeedBack</span><br />
                <textarea
                  type="textarea"
                  style="width: 100%; height: 245px"
                  maxlength="700"
                  v-model="feedback"
                ></textarea>
                <span style="font-size: 10px">(Max Character Limit : 700)</span>
              </div>
            </div>
            <button class="batch-button" style="margin-top: 10px" @click="submitFeedback()">
              Submit Evaluation
            </button>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="side-panel" :class="{active : toggleSidePanel}">
      <div class="side-panel-button" @click="toggleSidePanel = !toggleSidePanel">
          Attempted By
      </div>
      <a class="btn-floating btn-small" style="position: absolute;right: 20px;top: 20px;" @click="toggleSidePanel = false"><i class="material-icons">close</i></a>
      <div class="side-panel-content" style="height: 200px">
          <h6>Student Name</h6>
          <div v-for="(data,index) in subjectiveSolution[index].userSolutionDetails" :key="index" :value="data" @click="studentData(data)">
              <span>{{data.Name}}</span>
          </div>
      </div>
  </div> -->

    <!-- <div>
      </div> -->
    <div class="portrait">
      <div class="valign-wrapper">
        <div class="valign">
          <div style="margin-bottom: 100px">
            <div class="rotate"></div>
            <h5 class="light">Please rotate your device</h5>
            <span
              >We don't support portrat mode yet. Rotate your device to landscape mode for the best
              experience</span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import * as markerjs2 from "markerjs2";
import Flicking from "@egjs/vue3-flicking";
import { AutoPlay } from "@egjs/flicking-plugins";
import ProfileAvatar from "vue-profile-avatar";
import MobileAPI from "../Mobileapi";

export default {
  data() {
    return {
      plugins: [
        new AutoPlay({
          duration: 7000,
          direction: "NEXT",
          stopOnHover: false,
        }),
      ],
      subjectiveSolution: [],
      addremarks: [],
      studentSolution: [],
      solutionList: [],
      getEvaluationPoints: [],
      studName: null,
      totalLength: null,
      showScreen: false,
      showSolution: false,
      toggleSidePanel: false,
      showSubmit: false,
      check: false,
      index: null,
      instituteTestId: null,
      marksValidation: false,
      marksValidation1: false,
      marksValidation2: false,
      selected: false,
      leaderBg: [],
      initial: 0,
      j: 0,
      marks: "",
      feedback: "",
      demoimg: [
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcR2WhaHaUQYYM5_N3l3PYYehwa5DlkzsczE5tznD7muyqnaDLxdO_7dU3TVYIf9v2VzfZE&usqp=CAU",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQ2oestbWoyefdeY8HjeLrmmXaQo39yzMUQ6zSC-oscLaVohUAEH8OJ1vVbw1a0FpP07qE&usqp=CAU",
        "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcSzd9lqIoSnKVFrCZzRsyd9EuRmkHl8s4ZU2AEpqsnvZodZizT40y-YUkr2VblzkBpzDEs&usqp=CAU",
      ],
    };
  },
  activated() {
    this.j = 0;
    this.index = 0;
    this.marksValidation = false;
    this.marksValidation1 = false;
    this.marksValidation2 = false;
    this.instituteTestId = Number(this.$route.params.InstituteTestId);
    this.subjectiveSolution = [];
    this.totalLength = null;
    this.leaderBg = [
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#F0AE76",
      "#FC8686",
      "#F0AE76",
      "#56CD9E",
      "#69B0DD",
      "#6971DD",
      "#F0AE76",
    ];
    if (this.instituteTestId !== null) {
      this.$store.dispatch("showLoader", true);
      MobileAPI.getInsituteTestSubjectiveQuestionSolution(
        { InstituteTestId: this.instituteTestId },
        (response) => {
          this.subjectiveSolution = response.Data;
          this.totalLength = this.subjectiveSolution.length;
          this.$store.dispatch("showLoader", false);
          const data = {
            QuestionId: this.subjectiveSolution[this.index].QuestionId,
          };
          this.$store.dispatch("showLoader", true);
          MobileAPI.getEvaluationPoints(data, (response1) => {
            this.getEvaluationPoints = response1.data[0];
            this.$store.dispatch("showLoader", false);
          });
          if (this.subjectiveSolution.length > 0) {
            this.showScreen = true;
          }
        },
      );
    }
  },

  methods: {
    // showMarkerArea() {
    //   // create a marker.js MarkerArea
    //   const markerArea = new markerjs2.MarkerArea(this.$refs.imgRef);
    //   // attach an event handler to assign annotated image back to our image element
    //   markerArea.addEventListener("render", (event) => {
    //     this.$refs.imgRef.src = event.dataUrl;
    //   });
    //   // launch marker.js
    //   markerArea.show();
    // },
    validateNumber: (event) => {
      const keyCode = event.keyCode;
      if (keyCode < 48 || keyCode > 57) {
        event.preventDefault();
      }
    },
    studentData(data) {
      this.studName = data.Name;
      this.marksValidation = false;
      this.marksValidation1 = false;
      this.marksValidation2 = false;
      this.studentSolution = data;
      if (this.studentSolution.solutionUrlList.length > 0) {
        this.showSolution = true;
      }
      if (this.studentSolution.IsEvaluated === true) {
        this.marks = this.studentSolution.TeacherMarks;
        this.addremarks = this.studentSolution.EvaluationPoints.split(",");
        this.feedback = this.studentSolution.TeacherFeedBack;
      }
      if (this.studentSolution.IsEvaluated === null) {
        this.marks = "";
        this.addremarks = [];
        this.feedback = "";
        this.showSubmit = true;
      }
    },
    submitFeedback() {
      const user = this.$store.getters.user;
      if (this.marks > this.studentSolution.maximumMarks) {
        this.marksValidation = true;
        this.marksValidation1 = false;
        this.marksValidation2 = false;
        return;
      }
      if (this.marks < 0) {
        this.marksValidation1 = true;
        this.marksValidation = false;
        this.marksValidation2 = false;
        return;
      }
      if (this.marks === "") {
        this.marksValidation2 = true;
        this.marksValidation = false;
        this.marksValidation1 = false;
        return;
      }
      const data = {
        TeacherMarks: this.marks,
        SubjectiveQuestionEvaluationId:
          this.studentSolution.InstitutetestSubjectiveQuestionEvaluationId,
        TeacherFeedback: this.feedback,
        IsEvaluated: 1,
        EvaluationPoints: this.addremarks,
        TeacherId: user.UserId,
      };
      this.$store.dispatch("showLoader", true);
      MobileAPI.updateTeacherFeedbackForInstSubjectiveWeb(data, (response) => {
        this.isUpdated = response.data;
        this.$store.dispatch("showLoader", false);
        this.$store.dispatch("showLoader", true);
        MobileAPI.getInsituteTestSubjectiveQuestionSolution(
          { InstituteTestId: this.instituteTestId },
          (response2) => {
            this.$store.dispatch("showLoader", false);
            this.subjectiveSolution = response2.Data;
            const data1 = {
              QuestionId: this.subjectiveSolution[this.index].QuestionId,
            };
            this.$store.dispatch("showLoader", true);
            MobileAPI.getEvaluationPoints(data1, (response1) => {
              this.$store.dispatch("showLoader", false);
              this.getEvaluationPoints = response1.data[0];
            });
          },
        );
      });
      this.marks = "";
      this.feedback = "";
      this.addremarks = [];
      this.showSolution = false;
      this.marksValidation = false;
      this.marksValidation1 = false;
      this.marksValidation2 = false;
      this.$store.dispatch("showLoader", false);
    },
    nextQuestion() {
      this.index += 1;
      this.showSolution = false;
      this.studName = "";
      this.$store.dispatch("showLoader", true);
      MobileAPI.getInsituteTestSubjectiveQuestionSolution(
        { InstituteTestId: this.instituteTestId },
        (response) => {
          this.$store.dispatch("showLoader", false);
          this.subjectiveSolution = response.Data;
          const data = {
            QuestionId: this.subjectiveSolution[this.index].QuestionId,
          };
          this.$store.dispatch("showLoader", true);
          MobileAPI.getEvaluationPoints(data, (response1) => {
            this.$store.dispatch("showLoader", false);
            this.getEvaluationPoints = response1.data[0];
          });
        },
      );
    },
    prevQuestion() {
      this.$store.dispatch("showLoader", true);
      this.index -= 1;
      this.showSolution = false;
      this.studName = "";
      this.$store.dispatch("showLoader", true);
      MobileAPI.getInsituteTestSubjectiveQuestionSolution(
        { InstituteTestId: this.instituteTestId },
        (response) => {
          this.$store.dispatch("showLoader", false);
          this.subjectiveSolution = response.Data;
          const data = {
            QuestionId: this.subjectiveSolution[this.index].QuestionId,
          };
          this.$store.dispatch("showLoader", true);
          MobileAPI.getEvaluationPoints(data, (response1) => {
            this.$store.dispatch("showLoader", false);
            this.getEvaluationPoints = response1.data[0];
          });
        },
      );
      this.$store.dispatch("showLoader", false);
    },
    home() {
      this.$router.push({
        name: "Test Evaluation",
        params: { search: true },
      });
    },
  },
  components: {
    Flicking,
    ProfileAvatar,
  },
};
</script>

<style lang="scss" scoped>
@import url("../../node_modules/@egjs/vue3-flicking/dist/flicking-inline.css");

* {
  margin: 0;
  padding: 0;
}
.rotate {
  background: url("../assets/landscape.png");
  height: 100px;
  width: 100px;
  margin: 0 auto;
}

.portrait {
  display: none;
}

@media screen and (orientation: portrait) {
  .evaluation {
    display: none;
  }

  .portrait {
    display: block;
    background: #3a322f;
    height: 100%;
    width: 100%;
    position: absolute;
    top: 0;
    z-index: 1001;
    text-align: center;
    color: #ffffffe6;
    left: 0;
    padding: 0 30px;
  }
}
.student-name {
  display: flex;
  margin-bottom: 5px;
  margin-top: 5px;
}

.active1 {
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px, rgb(51, 51, 51) 0px 0px 0px 3px;
}
@font-face {
  font-family: PackMan;
  src: url(../font/pac-font.ttf);
}
.heading {
  margin-top: 10px;
  /* font-family: Open Sans;
  font-style: normal; */
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */
  color: #333333;
  width: 247px;
  height: 25px;
}
.attempted-by {
  height: 150px;
  overflow-y: scroll;
  margin-top: 10px;
}
.attempted-by::-webkit-scrollbar {
  width: 10px;
  color: grey;
}
.heading1 {
  margin-top: 10px;
  /* font-family: Open Sans;
  font-style: normal; */
  font-weight: 600;
  font-size: 18px;
  line-height: 25px;
  /* identical to box height */
  color: #333333;
  height: 25px;
}
.batch-button {
  width: 194px;
  height: 35px;
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  border-radius: 5px;
  cursor: pointer;
  border: none;
  color: #ffffff;
}
.batch-button:disabled {
  background: linear-gradient(90.2deg, #181818 0.16%, #9b9b9b 98.41%);
  cursor: not-allowed;
}
.batch-button1 {
  width: 104px;
  height: 35px;
  background: linear-gradient(90.2deg, #ff9421 0.16%, #fbad26 98.41%);
  border-radius: 5px;
  cursor: pointer;
  border: none;
  color: #ffffff;
}
.batch-button2 {
  width: 104px;
  height: 35px;
  background: linear-gradient(90.2deg, #ff0000 0.16%, #ff5d5d 98.41%);
  border-radius: 5px;
  cursor: pointer;
  border: none;
  color: #ffffff;
}
.batch-button1:disabled {
  opacity: 0;
}
.card-radius {
  border-radius: 5px;
}
.studentlist {
  font-size: 15px;
  font-weight: 600;
  cursor: pointer;
}
.selected {
  color: #70ff11;
}
.leader-avatar {
  width: 20px !important;
  height: 20px !important;
  font-size: 9px !important;
  /* background: #FC8686 !important; */
  color: white !important;
  margin-right: 10px;
  font-family: "PackMan" !important;
}

// .side-panel {
//     background: #fff;
//     box-shadow: 0 0 10px rgb(0 0 0 / 19%);
//     position: fixed;
//     left: 100%;
//     top: 60px;
//     width: 350px;
//     transition: all 0.5s;
//     z-index: 99;
// }
// .side-panel-button {
//     background: #3d3d3d;
//     font-size: 1rem;
//     text-transform: uppercase;
//     padding: 18px 30px;
//     color: #fff;
//     font-weight: 700;
//     border-radius: 80px 20px 0 0;
//     position: absolute;
//     left: 0;
//     top: 0;
//     width: 195px;
//     text-align: center;
//     left: -122px;
//     top: 25%;
//     transform: rotate(-90deg);
//     cursor: pointer;
// }

// .side-panel-content {
//     overflow: auto;
//     padding: 6px 32px;
// }
// .active {
//     left: calc(100% - 350px);
// }
// .flicking-camera{
//   will-change: unset !important;
//   transform: translate(0%) !important;
//   }
.flicking-camera > * {
  width: 100% !important;
}
</style>
